import React from 'react'
import styles from './FeaturedAnalyses.module.scss'

const FeaturedAnalyses = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.tag}>Easy to use</div>
          <h2>No longer reserved for technical experts</h2>
          <p>
            GIS analysis used to be for experts only. With Atlas, anyone can
            perform advanced analyses without prior knowledge.
          </p>
        </div>
        <div className={styles.layout}>
          <div className={styles.row}>
            <div className={styles.left}>
              <div>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: '#FFF4E7' }}
                >
                  <img src="/icons/buffer-icon.svg" alt="Buffer icon" />
                </div>
                <h3>Calculate Buffers</h3>
                <p>
                  Create zones around any feature on your map with just a few
                  clicks.
                </p>
                <p>
                  Whether you’re planning urban developments, assessing
                  environmental impact, or simply finding all schools within a
                  1-mile radius of a park.
                </p>
              </div>
            </div>
            <div className={`${styles.right} ${styles.reverse}`}>
              <div className={styles.imageContainer}>
                <video
                  src="https://cdn.atlas.co/landing-pages/home-buffer.mp4"
                  loop
                  autoPlay
                  muted
                  playsInline
                  playsinline="true"
                  webkit-playsinline="true"
                  x5-playsinline="true"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 0,
                    display: 'block',
                    objectFit: 'cover',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    objectPosition: '50% 50%'
                  }}
                ></video>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.right} ${styles.mobileMargin}`}>
              <div className={styles.imageContainer}>
                <video
                  src="https://cdn.atlas.co/landing-pages/home-travel-time.mp4"
                  loop
                  autoPlay
                  muted
                  playsInline
                  playsinline="true"
                  webkit-playsinline="true"
                  x5-playsinline="true"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 0,
                    display: 'block',
                    objectFit: 'cover',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    objectPosition: '50% 50%'
                  }}
                ></video>
              </div>
            </div>
            <div className={styles.left}>
              <div>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: '#CDE4E1' }}
                >
                  <img src="/icons/cycling-icon.svg" alt="Travel time icon" />
                </div>
                <h3>Analyze Travel Times</h3>
                <p>
                  Determine how long it will take to reach various destinations,
                  such as identifying areas within a 15-minute drive from your
                  office.
                </p>
                <p>
                  Input your location, set your travel parameters, and visualize
                  the accessible areas in no time.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.left}>
              <div>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: '#FFBF6E' }}
                >
                  <img src="/icons/nearest-icon.svg" alt="Nearest icon" />
                </div>
                <h3>Find Nearest Locations Fast</h3>
                <p>Need to locate the closest facilities in an emergency?</p>
                <p>
                  Input any location, select your category of interest, such as
                  hospitals or fire stations, and instantly see the closest
                  options highlighted on your map.
                </p>
              </div>
            </div>
            <div className={`${styles.right} ${styles.reverse}`}>
              <div className={styles.imageContainer}>
                <video
                  src="https://cdn.atlas.co/landing-pages/home-nearest.mp4"
                  loop
                  autoPlay
                  muted
                  playsInline
                  playsinline="true"
                  webkit-playsinline="true"
                  x5-playsinline="true"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 0,
                    display: 'block',
                    objectFit: 'cover',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    objectPosition: '50% 50%'
                  }}
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <h3>...and over 50 other analyses</h3>
          <p>
            Atlas offers various tools to help you understand your
            location-based data. Whether you're a beginner or an expert, you can
            perform advanced analyses easily.
          </p>
        </div>
      </div>
    </div>
  )
}

export default FeaturedAnalyses
