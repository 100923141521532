import React from 'react'

const CursorIcon = ({ size = 20, color = 'black' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 26.004 26.004"
    >
      <path
        id="Path_9"
        data-name="Path 9"
        d="M27.928,11.345a1.25,1.25,0,0,0-.732-.732h-.065L3.688,2.078a1.255,1.255,0,0,0-1.61.764,1.305,1.305,0,0,0,0,.862L10.614,27.18a1.246,1.246,0,0,0,1.187.829h0a1.25,1.25,0,0,0,1.171-.8l4.065-10.161L27.2,12.987a1.263,1.263,0,0,0,.732-1.626Z"
        transform="translate(-2.005 -2.006)"
      />
    </svg>
  )
}

export default CursorIcon
