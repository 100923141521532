import React from 'react'
import PageHeader from '../components/home/PageHeader'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import ProductOverview from '../components/home/ProductOverview'
import FeaturedPosts from '../components/home/FeaturedPosts'
import Collaborative from '../components/home/Collaborative'
import FeaturedAnalyses from '../components/home/FeaturedAnalyses'
import Spotlight from '../components/home/Spotlight'

const HomePage = ({ data }) => {
  return (
    <Layout
      meta={{
        description:
          'Build interactive web maps with Atlas. The new standard for GIS software. Simple and powerful. Step into Atlas to explore, analyze and share geospatial data.',
        title: 'Atlas: Your all-in-one map builder',
        type: 'website'
      }}
      title={'Atlas: GIS & Maps in the Browser'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Collaborative />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ProductOverview />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <FeaturedAnalyses />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <FeaturedPosts posts={data?.posts?.nodes} />
        </Fade>
      </main>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      limit: 3
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          author {
            name
            role
          }
          meta {
            title
            description
          }
        }
        fields {
          slug
        }
      }
    }
    maps: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fileAbsolutePath: { regex: "/community-maps/" }
        frontmatter: { featured: { eq: true } }
      }
      limit: 2
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          link
          tag
          featured
          trending
          newest
          created
          creator
        }
      }
    }
  }
`
