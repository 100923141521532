import React from 'react'
import styles from './Collaborative.module.scss'

const Collaborative = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.tag}>Collaborative</div>
          <h2>
            Bring your team closer
            <br />— no matter where they are
          </h2>
          <p>
            Collaborate on editing in real time. It’s easy to upload data, use
            comments and annotations to keep the process moving.
          </p>
        </div>
        <div className={styles.videoContainer}>
          <video
            src="https://cdn.atlas.co/landing-pages/home-collaborative.mp4"
            loop
            autoPlay
            muted
            playsInline
            playsinline="true"
            webkit-playsinline="true"
            x5-playsinline="true"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 0,
              display: 'block',
              objectFit: 'cover',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              objectPosition: '50% 50%'
            }}
          ></video>
        </div>
      </div>
    </div>
  )
}

export default Collaborative
